import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function DocVisualCrossVisualCompareTable() {
  
  const crossDocument = useSelector(state => state.crossDocument);
  const crossSTSentenceId = useSelector(state => state.crossSTSentenceId);
  const crossCPSentenceId = useSelector(state => state.crossCPSentenceId);
  const crossCompareCategory = useSelector(state => state.crossCompareCategory);

  const [validationTableData, setValidationTableData] = useState([]);
  
  useEffect(() => {
    const newTableData = [];
    if (crossSTSentenceId) {
      const matchedSentence = crossDocument.find(item => item.sentenceId === crossSTSentenceId);
      if (matchedSentence) {
        newTableData.push({
          STsentenceId: matchedSentence.sentenceId,
          STsentence: matchedSentence.sentence,
          STpart: matchedSentence.part,
          STproperty: matchedSentence.property,
          STvalue: matchedSentence.value,
          STdoc: matchedSentence.doc,
          STpage: matchedSentence.page,
          STsource: matchedSentence.source,
        });
      }
    }
    if (crossCPSentenceId !== null) {
      newTableData.push({
        CPsentenceId: crossCPSentenceId.sentenceId,
        CPsentence: crossCPSentenceId.sentence,
        CPpart: crossCPSentenceId.part,
        CPproperty: crossCPSentenceId.property,
        CPvalue: crossCPSentenceId.value,
        CPsimilarity: crossCPSentenceId.similarity,
        CPdoc: crossCPSentenceId.doc,
        CPpage: crossCPSentenceId.page,
        CPsource: crossCPSentenceId.source,
      });
    }
    setValidationTableData(newTableData);
  }, [crossSTSentenceId, crossCPSentenceId, crossDocument]);

  const SentenceTableRow = styled(TableRow)({
    height: 'calc(100vh - 620px)',
  });
  
  return (
    <TableContainer component={Paper} sx={{ height: '100%', }} >
      <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">교차검증</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#F0F4C3' }}>{validationTableData[0]?.STdoc || '-'} / {validationTableData[0]?.STpage || '-'} page / {validationTableData[0]?.STsentenceId || '-'} Row ID</TableCell>
              <TableCell align="center" sx={{ backgroundColor: '#FFECB3' }}>{validationTableData[1]?.CPdoc || '-'} / {validationTableData[1]?.CPpage || '-'} page / {validationTableData[1]?.CPsentenceId || '-'} Row ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" sx={{ width:50, backgroundColor: '#F5F5F5' }}>공종</TableCell>
              <TableCell align="center" sx={{ width:300, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc' }}>{validationTableData[0]?.STsource || '-'}</TableCell>
              <TableCell align="center" sx={{ width:300 }}>{validationTableData[1]?.CPsource || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ width:50, backgroundColor: '#F5F5F5' }}>Part</TableCell>
              <TableCell align="center" sx={{ width:300, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc',
                  fontWeight: crossCompareCategory === 'part' ? 'bold' : 'normal', color: crossCompareCategory === 'part' ? 'blue' : 'inherit', }}>
                {validationTableData[0]?.STpart || 'ㅡ'}
              </TableCell>
              <TableCell align="center" sx={{ width:300, fontWeight: crossCompareCategory === 'part' ? 'bold' : 'normal', color: crossCompareCategory === 'part' ? 'blue' : 'inherit', }}>
                {validationTableData[1]?.CPpart || 'ㅡ'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ width:50, backgroundColor: '#F5F5F5' }}>Property</TableCell>
              <TableCell align="center" sx={{ width:300, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc',
                  fontWeight: crossCompareCategory === 'property' ? 'bold' : 'normal', color: crossCompareCategory === 'property' ? 'blue' : 'inherit', }}>
                {validationTableData[0]?.STproperty || 'ㅡ'}
              </TableCell>
              <TableCell align="center" sx={{ width:300, fontWeight: crossCompareCategory === 'property' ? 'bold' : 'normal', color: crossCompareCategory === 'property' ? 'blue' : 'inherit', }}>
                {validationTableData[1]?.CPproperty || 'ㅡ'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ width:50, backgroundColor: '#F5F5F5' }}>Value</TableCell>
              <TableCell align="center" sx={{ width:300, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc',
                  fontWeight: crossCompareCategory === 'value' ? 'bold' : 'normal', color: crossCompareCategory === 'value' ? 'blue' : 'inherit', }}>
                {validationTableData[0]?.STvalue || 'ㅡ'}
              </TableCell>
              <TableCell align="center" sx={{ width:300, fontWeight: crossCompareCategory === 'value' ? 'bold' : 'normal', color: crossCompareCategory === 'value' ? 'blue' : 'inherit', }}>
                {validationTableData[1]?.CPvalue || 'ㅡ'}
              </TableCell>
            </TableRow>
            <SentenceTableRow>
              <TableCell align="center" sx={{ width:50, backgroundColor: '#F5F5F5' }}>문장</TableCell>
              <TableCell sx={{ width:300, borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc',}}>
                {validationTableData[0]?.STsentence || ''}
              </TableCell>
              <TableCell sx={{ width:300, }}>
                {validationTableData[1]?.CPsentence || ''}
              </TableCell>
            </SentenceTableRow>
            <TableRow>
              <TableCell align="center" sx={{ width: 50, backgroundColor: '#F5F5F5' }}>유사도</TableCell>
              <TableCell align="center" colSpan={2} sx={{ pl: 0.5, borderLeft: '1px solid #ccc', fontWeight: crossCompareCategory === 'similarity' ? 'bold' : 'normal' }}>
                {validationTableData.length > 1 && validationTableData[1].CPsimilarity !== undefined ? validationTableData[1].CPsimilarity + '%' : 'ㅡ'}
              </TableCell>
            </TableRow>
          </TableBody>
      </Table>
    </TableContainer>
  );
}
