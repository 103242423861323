import React, { useState } from "react";
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import DrawerContent from "../components/main/DrawerContent.jsx";
import AppBarContent from "../components/main/AppBarContent.jsx";
import ModuleCard from "../components/main/ModuleCard.jsx";
import MyPage from "../components/common/MyPage.jsx";
import Download from "../components/common/Download.jsx";
import Cloud from "./Cloud.jsx";
import DrawVisual from "./DrawVisual.jsx";
import DocRisk from "./DocRisk.jsx";
import DocRiskVisual from "../components/doc/risk/DocRiskVisual.jsx";
import DocVisual from "./DocVisual.jsx";
import DocVisualRiskTem from "../components/doc/visualRisk/DocVisualRiskTem.jsx";
import DocVisualRisk from "../components/doc/visualRisk/DocVisualRisk.jsx";
import Admin from "./Admin.jsx";
import Footer from "../components/main/Footer.jsx";
import UserAuthCheckAPI from '../services/common/UserAuthCheckAPI.jsx'
import { useDispatch, useSelector } from 'react-redux';
import { setUserDialog, setUserLoginAuth } from '../actions/index.js';
import DocVisualCross from '../components/doc/visualCross/DocVisualCross.jsx';
import DocVisualCrossVisual from '../components/doc/visualCross/DocVisualCrossVisual.jsx';
import SheetVisual from "./SheetVisual.jsx";
import SheetVisualPage from "./SheetVisualPage.jsx";

export default function Module() {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const userDialog = useSelector((state) => state.userDialog);

  const RedirectToHomeIfNotAdmin = ({ children }) => {
    return userInfo.roles.includes("ADMIN") ? (
      children
    ) : (
      <Navigate to="/module" />
    );
  };

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setDrawer({ ...drawer, left: open });
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <UserAuthCheckAPI />
      <CssBaseline />
      <AppBarContent toggleDrawer={toggleDrawer} />
      <Drawer
        anchor="left"
        open={drawer["left"]}
        onClose={toggleDrawer(false)}
        transitionDuration={{ enter: 400, exit: 800 }}
      >
        <DrawerContent anchor="left" toggleDrawer={toggleDrawer} />
      </Drawer>

      <Routes>
        <Route path="/" element={<ModuleCard />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/drawdownload" element={<Download />} />
        <Route path="/sheetdownload" element={<Download />} />
        <Route path="/drawcloud" element={<Cloud />} />
        <Route path="/sheetcloud" element={<Cloud />} />
        <Route path="/doccloud" element={<Cloud />} />
        <Route path="/drawvisual" element={<DrawVisual />} />
        <Route path="/sheetvisual" element={<SheetVisual />} />
        <Route path="/sheetvisual/sheet" element={<SheetVisualPage />} />
        <Route path="/risk" element={<DocRisk />} />
        <Route path="/riskvisual" element={<DocRiskVisual />} />
        <Route path="/docvisual" element={<DocVisual />} />
        <Route path="/docvisual/risk" element={<DocVisualRiskTem />} />
        <Route path="/docvisual/riskvisual" element={<DocVisualRisk />} />
        <Route path="/docvisual/cross" element={<DocVisualCross />} />
        <Route path="/docvisual/crossvisual" element={<DocVisualCrossVisual />} />
        <Route path="/admin" element={
          <RedirectToHomeIfNotAdmin>
            <Admin />
          </RedirectToHomeIfNotAdmin>
        } />
        <Route
          path="/admin"
          element={
            <RedirectToHomeIfNotAdmin>
              <Admin />
            </RedirectToHomeIfNotAdmin>
          }
        />
      </Routes>

      <Footer />

      <Dialog
        open={userDialog}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            dispatch(setUserDialog(false));
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.95)",
          },
        }}
      >
        <DialogTitle>회원 정보 만료</DialogTitle>
        <DialogContent>
          <DialogContentText>다시 로그인 해주세요.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setUserLoginAuth(false));
              dispatch(setUserDialog(false));
              window.location.reload();
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
