import CreateIcon from '@mui/icons-material/Create';
import { Box, TextField, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';

export default function DocVisualCrossVisualOpinion() {

  const feedbacks = [
    {
      author: '책임자@홍길동',
      target: '총괄 책임자',
      opinion: '검증 바랍니다.',
      timestamp: '24.07.02 16:05:01',
    },
    {
      author: 'pipe@김철수',
      target: 'Pipe',
      opinion: 'vertically suspended로 pumps입니다.',
      timestamp: '24.07.02 16:05:01',
    },
    {
      author: 'machine@정수근',
      target: 'machine',
      opinion: 'with suction tail pipe로 pumps입니다.',
      timestamp: '24.07.04 16:51:01',
    },
    {
      author: '책임자@홍길동',
      target: '총괄 책임자',
      opinion: '12page 공정 공종의 pipes가 pumps로 변경되어야 하는지 TR 보내도록 하겠습니다.',
      timestamp: '24.07.05 10:05:11',
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ mb:2, display:'flex', alignItems:'center', justifyContent: 'space-between', }}>
        <Box sx={{ display:'flex', alignItems:'end', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
          <Typography variant='h5'> 교차검증 의견 </Typography>
        </Box>
        <Box sx={{ display:'flex' }}>
          <Button variant="contained" size="small" color='success' sx={{display:'flex', alignItems:'center', px:1, whiteSpace: 'nowrap' }} > 완료 </Button>
          <Button variant="contained" size="small" sx={{display:'flex', alignItems:'center', px:1, whiteSpace: 'nowrap' }} > 진행중 </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mb: 2, }}>
        <TextField
          label="의견 작성"
          variant="filled"
          fullWidth
          size="small"
          sx={{ marginRight: 1,  height:'10%' }}
        />
        <IconButton aria-label="delete">
          <CreateIcon />
        </IconButton>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F5F5F5' }}>
              <TableCell>작성자</TableCell>
              <TableCell>공유대상</TableCell>
              <TableCell>의견</TableCell>
              <TableCell>작성시간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.map((feedback, index) => (
              <TableRow key={index}>
                <TableCell>{feedback.author}</TableCell>
                <TableCell>{feedback.target}</TableCell>
                <TableCell>{feedback.opinion}</TableCell>
                <TableCell>{feedback.timestamp}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}