import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { blue, green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import AdminListAPI from '../../services/admin/AdminListAPI';
import { setPasswordResetAlert, setUserDeleteAlert } from '../../actions';
import { useState } from 'react';


export default function AdministratorContent() {

  const dispatch = useDispatch();

  const administratorInfo = useSelector(state => state.administratorInfo);
  const passwordResetAlert = useSelector(state => state.passwordResetAlert);
  const userDeleteAlert = useSelector(state => state.userDeleteAlert);
  
  const { passwordReset, userDelete, userClassAdmin, userClassUser, userClassFree, userClassRoleAll } = AdminListAPI({});

  const [selectedId, setSelectedId] = useState(null);
  const [alignment, setAlignment] = useState({}); 
  const [adminStatus, setAdminStatus] = useState({});
  
  const handleAlignment = (id, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(prev => ({ ...prev, [id]: newAlignment }));
    }
  };

  const classInfo = (params) => {
    const roles = params.row.roles;
  
    if (roles && roles.includes("ADMIN")) {
      return <Avatar sx={{ width: 30, height: 30 }}>A</Avatar>;
    } else if (roles && (roles.includes('USER_DRAW') || roles.includes('USER_SHEET') || roles.includes('USER_DOC'))) {
      return <Avatar sx={{ bgcolor: blue[300], width: 30, height: 30 }}>M</Avatar>;
    } else {
      return <Avatar sx={{ bgcolor: green[300], width: 30, height: 30 }}>D</Avatar>;
    }
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#F5F5F5',
            },
          },
        },
      },
    },
  });

  const rows = administratorInfo.map((info, index) => ({
    index: index+1,
    name: info.name,
    email: info.username,
    registeredAt: info.registeredAtFormed,
    roles: info.roles,
    id: info.id,
  }));

  const columns = [
    { field: 'index', headerName: '번호', width: 50, },
    { field: 'class', headerName: '현 등급', width: 70, renderCell: classInfo, },
    { field: 'name', headerName: '이름', width: 150, },
    { field: 'email', headerName: '이메일', width: 200, },
    { field: 'registeredAt', headerName: '가입 날짜', width: 200, },
    {
      field: 'grade',
      headerName: '등급',
      width: 180,
      renderCell: (params) => {
        const hasPaidRole = 
          params.row.roles.includes("USER_DRAW") || 
          params.row.roles.includes("USER_SHEET") || 
          params.row.roles.includes("USER_DOC");
    
        const currentAlignment = alignment[params.id] !== undefined 
          ? alignment[params.id] 
          : (hasPaidRole ? 'pay' : 'free'); // USER, ADMIN 확인 로직 제거
    
        const handleToggleChange = (newAlignment) => {
          handleAlignment(params.id, newAlignment); // 상태 업데이트
    
          // API 호출
          if (newAlignment === 'pay') {
            userClassRoleAll(params.id); // 유료로 변경 시 API 호출
          } else if (newAlignment === 'free') {
            userClassFree(params.id); // 무료로 변경 시 API 호출
          }
        };
    
        return (
          <ToggleButtonGroup
            color="primary"
            value={currentAlignment}
            exclusive
            onChange={(event, newAlignment) => handleToggleChange(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="pay">
              유료
            </ToggleButton>
            <ToggleButton value="free">
              무료
            </ToggleButton>
          </ToggleButtonGroup>
        );
      },
    },
    {
      field: 'admin',
      headerName: '관리자 권한',
      width: 180,
      renderCell: (params) => {
        const isAdmin = params.row.roles.includes("ADMIN");
        
        // adminStatus 상태를 기반으로 버튼의 선택 상태를 결정
        const currentAdminStatus = adminStatus[params.id] !== undefined ? adminStatus[params.id] : isAdmin;

        const handleToggleChange = () => {
          // API 호출
          if (currentAdminStatus) {
            userClassUser(params.id); // ADMIN일 경우 사용자 권한 API 호출
          } else {
            userClassAdmin(params.id); // ADMIN이 아닐 경우 관리자 권한 API 호출
          }
          // 상태 업데이트
          setAdminStatus(prev => ({ ...prev, [params.id]: !currentAdminStatus }));
        };

        return (
          <ToggleButton
            color="primary"
            value="check"
            selected={currentAdminStatus}
            onChange={handleToggleChange}
          >
            <CheckIcon />
          </ToggleButton>
        );
      },
    },
    { 
      field: 'passWord', 
      headerName: '비밀번호 초기화', 
      width: 180, 
      renderCell: (params) => (
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={() => {
            setSelectedId(params.row.id);
            dispatch(setPasswordResetAlert(true));
          }}
        >
          초기화
        </Button>
      ),
    },
    { 
      field: 'out', 
      headerName: '회원 탈퇴', 
      width: 130, 
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setSelectedId(params.row.id);
            dispatch(setUserDeleteAlert(true));
          }}
        >
          탈퇴
        </Button>
      ),
    },
  ];

  return (
    <Grid item xs={12} sm={10} md={10} sx={{ height: 'calc(100vh - 160px)' }}>
      <Paper elevation={6} sx={{height: '100%', position: 'relative'}}>

        <ThemeProvider theme={theme}>
          <DataGrid
            sx={{height:'calc(100vh - 170px)', '&:hover': {cursor: 'default'}}}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableAutoFocus
            disableVirtualization
          />
        </ThemeProvider>


        <Dialog
            open={passwordResetAlert}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                dispatch(setPasswordResetAlert(false));
              }
            }}
          >
            <DialogTitle>
              {"비밀번호 초기화"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                해당 회원의 비밀번호를 초기화하시겠습니까?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {dispatch(setPasswordResetAlert(false)); setSelectedId(null); }}> 아니오 </Button>
              <Button color='error' onClick={ () => {passwordReset(selectedId)}}> 네 </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={userDeleteAlert}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                dispatch(setUserDeleteAlert(false));
              }
            }}
          >
            <DialogTitle>
              {"회원 탈퇴"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                해당 회원을 탈퇴시키겠습니까?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {dispatch(setUserDeleteAlert(false)); setSelectedId(null); }}> 아니오 </Button>
              <Button color='error' onClick={ () => {userDelete(selectedId)}}> 네 </Button>
            </DialogActions>
          </Dialog>


      </Paper>
    </Grid>
  );
}