const mainColors = {
  POINT_BLUE: "#0070C0",
  BACKGROUND: "#F7F8FC",
};

const blockColors = {
  BORDER_GRAY: "#e0e0e0",
};

const colors = {
  WHITE: "#fff",
  GRAY_100: "#f9f9f9",
  GRAY_200: "#E7E7E7",
  GRAY_500: "#8E8E8E",
  GRAY_700: "#555555",
  BLACK: "#000",
};

export { mainColors, blockColors, colors };
