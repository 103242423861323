import { Avatar, Button, Grid, LinearProgress, Paper, ThemeProvider, Typography, createTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { blue, green } from '@mui/material/colors';
import AdminListAPI from '../../services/admin/AdminListAPI';


export default function AdministratorData() {

  const administratorInfo = useSelector(state => state.administratorInfo);
  
  const { adminUserData } = AdminListAPI({});

  const classInfo = (params) => {
    const roles = params.row.roles;
  
    if (roles && roles.includes("ADMIN")) {
      return <Avatar sx={{ width: 30, height: 30 }}>A</Avatar>;
    } else if (roles && (roles.includes('USER_DRAW') || roles.includes('USER_SHEET') || roles.includes('USER_DOC'))) {
      return <Avatar sx={{ bgcolor: blue[300], width: 30, height: 30 }}>M</Avatar>;
    } else {
      return <Avatar sx={{ bgcolor: green[300], width: 30, height: 30 }}>D</Avatar>;
    }
  };

  const ProgressBar = ({ roles, used, total, usedText, totalText }) => {
    const percentage = total > 0 ? (used / total) * 100 : 0;

    const hasUserRoles = roles && (roles.includes('USER_DRAW') || roles.includes('USER_SHEET') || roles.includes('USER_DOC'));
    const rolesTotal = hasUserRoles ? '300MB' : '30MB';
  
    const displayUsedText = 
      usedText === '-' ? '0MB' : 
      usedText == null ? '-' : 
      usedText;
  
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <LinearProgress variant="determinate" value={percentage} />
        <Typography variant="body2">{`${displayUsedText} / ${rolesTotal}`}</Typography>
      </div>
    );
  };
  
  

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#F5F5F5',
            },
          },
        },
      },
    },
  });

  const rows = administratorInfo.map((info, index) => ({
    index: index + 1,
    name: info.name,
    email: info.username,
    roles: info.roles,
    id: info.id,
    drawUsedVolumeB: info.drawVolume?.originalVolume !== undefined ? info.drawVolume.originalVolume : '-',
    drawTotalVolumeB: info.drawVolume?.originalUserVolume || '-', 
    drawUsedVolumeText: info.drawVolume?.volume || null, 
    drawTotalVolumeText: info.drawVolume?.userVolume || '-', 
    sheetUsedVolumeB: info.sheetVolume?.originalVolume !== undefined ? info.sheetVolume.originalVolume : '-',
    sheetTotalVolumeB: info.sheetVolume?.originalUserVolume || '-', 
    sheetUsedVolumeText: info.sheetVolume?.volume || null, 
    sheetTotalVolumeText: info.sheetVolume?.userVolume || '-', 
    docUsedVolumeB: info.docVolume?.originalVolume !== undefined ? info.docVolume.originalVolume : '-',
    docTotalVolumeB: info.docVolume?.originalUserVolume || '-', 
    docUsedVolumeText: info.docVolume?.volume || null, 
    docTotalVolumeText: info.docVolume?.userVolume || '-', 
    parsingUsed: info.parserCount?.parsingCount !== undefined ? info.parserCount.parsingCount : '-',
    parsingTotal: info.parserCount?.userParsingCount || '-',
    analysisUsed: info.analysisCount?.extractCount !== undefined ? info.analysisCount.extractCount : '-',
    analysisTotal: info.analysisCount?.userExtractCount || '-', 
  }));
  
  

  const columns = [
    { field: 'index', headerName: '번호', width: 50, },
    { field: 'class', headerName: '현 등급', width: 70, renderCell: classInfo, },
    { field: 'name', headerName: '이름', width: 150, },
    { field: 'email', headerName: '이메일', width: 200, },
    { 
      field: '*', 
      headerName: '', 
      width: 100, 
      renderCell: (params) => (
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={() => {
            adminUserData(params.row.id);
          }}
        >
          조회
        </Button>
      ),
    },
    { 
      field: 'drawUsedVolumeB', 
      headerName: '도면', 
      flex: 1, 
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.drawUsedVolumeB === '-' ? '-' : params.row.drawUsedVolumeB === 0 ? 0 : params.row.drawUsedVolumeB} 
          total={params.row.drawTotalVolumeB} 
          usedText={params.row.drawUsedVolumeText} 
          totalText={params.row.drawTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'sheetUsedVolumeB', 
      headerName: '시트', 
      flex: 1, 
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.sheetUsedVolumeB === '-' ? '-' : params.row.sheetUsedVolumeB === 0 ? 0 : params.row.sheetUsedVolumeB} 
          total={params.row.sheetTotalVolumeB} 
          usedText={params.row.sheetUsedVolumeText} 
          totalText={params.row.sheetTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'docUsedVolumeB', 
      headerName: '문서', 
      flex: 1,
      renderCell: (params) => (
        <ProgressBar 
          roles={params.row.roles}
          used={params.row.docUsedVolumeB === '-' ? '-' : params.row.docUsedVolumeB === 0 ? 0 : params.row.docUsedVolumeB} 
          total={params.row.docTotalVolumeB} 
          usedText={params.row.docUsedVolumeText}
          totalText={params.row.docTotalVolumeText} 
        />
      ),
    },
    { 
      field: 'parsing', 
      headerName: 'parsing', 
      width: 80, 
      renderCell: (params) => {
        const used = params.row.parsingUsed === '-' ? '-' : params.row.parsingUsed === 0 ? 0 : params.row.parsingUsed;
        const hasUserRoles = params.row.roles && (params.row.roles.includes('USER_DRAW') || params.row.roles.includes('USER_SHEET') || params.row.roles.includes('USER_DOC'));
        const total = hasUserRoles ? 50 : 10;
    
        return `${used} / ${total}`;
      },
    },
    { 
      field: 'analysis', 
      headerName: 'analysis', 
      width: 80, 
      renderCell: (params) => {
        const used = params.row.analysisUsed === '-' ? '-' : params.row.analysisUsed === 0 ? 0 : params.row.analysisUsed;
        const hasUserRoles = params.row.roles && (params.row.roles.includes('USER_DRAW') || params.row.roles.includes('USER_SHEET') || params.row.roles.includes('USER_DOC'));
        const total = hasUserRoles ? 15 : 3;
    
        return `${used} / ${total}`;
      },
    },
  ];

  return (
    <Grid item xs={12} sm={10} md={10} sx={{ height: 'calc(100vh - 160px)' }}>
      <Paper elevation={6} sx={{height: '100%', position: 'relative'}}>

        <ThemeProvider theme={theme}>
          <DataGrid
            sx={{height:'calc(100vh - 170px)', '&:hover': {cursor: 'default'}}}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableAutoFocus
            disableVirtualization
          />
        </ThemeProvider>

      </Paper>
    </Grid>
  );
}