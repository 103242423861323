import React, { useEffect, useRef, useState } from "react";
import "./SheetVisualPage.css";
import visualize_image from "../helpers/selectObject";
import { SheetInfoManager } from "../helpers/sheetInfoLoader";
import styled from "styled-components";
import { mainColors } from "../constants/index";
import { Metadata } from "../components/sheet/Metadata";
import ObjectData from "../components/sheet/ObjectData";
import { useLocation } from "react-router-dom";

const SheetVisualPage = () => {
  const location = useLocation();
  const { fileName } = location.state || {};

  const extratNameAndPage = (fileName) => {
    let newStr = fileName.split("/");
    let name = newStr.pop();
    let page = parseInt(name.split("_")[0], 10);

    return {
      path: newStr.join("/"),
      name: name,
      page: page,
    };
  };

  const { path, name, page } = extratNameAndPage(fileName);

  let PAGE = page;
  let imgSrc = `/proxy/sheet-image/?imageKey=${path}/${name}`;
  let projectFolder = `/proxy/sheet-data/?jsonKey=${path}/sheet_info.json`;

  const metadataRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [selectedObj, setSelectedObj] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      let sheet_info_manager = new SheetInfoManager(projectFolder);
      await sheet_info_manager.load_sheet_info();
      sheet_info_manager.make_obj_map();
      visualize_image(sheet_info_manager, PAGE, imgSrc, setSelectedObj);
    };

    initialize();
  }, [PAGE, imgSrc, projectFolder]);

  useEffect(() => {
    const handleScroll = () => {
      if (metadataRef.current) {
        const boundingRect = metadataRef.current.getBoundingClientRect();

        if (boundingRect.bottom <= 64) {
          setIsFixed(true);
          setTopOffset(80);
        } else {
          setIsFixed(false);
          setTopOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <SheetContainer>
      <Metadata ref={metadataRef} />

      <VisualContainer>
        <GridItemContainer id="sheet-image-container">
          <div id="grid-item1" className="grid-item"></div>
        </GridItemContainer>

        <ObjectData
          topOffset={topOffset}
          isFixed={isFixed}
          selectedObj={selectedObj}
        />
      </VisualContainer>
    </SheetContainer>
  );
};

const SheetContainer = styled.div`
  position: relative;
  background-color: ${mainColors.BACKGROUND};
`;

const VisualContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const GridItemContainer = styled.div`
  place-items: center;
  padding: 5px 20px 5px;
  flex: 0 0 70%;
`;

export default SheetVisualPage;
